<template>
  <div class="gap-4 bg-white p-3" style="height: calc(100% - 110px);">
    <div class="flex my-2 items-center justify-between">
      <div class="flex items-center cursor-pointer" @click="$router.push({ name: 'pharmasan.gestion.calidad.mapa-procesos'})">
        <i :class="`pi pi-angle-left my-auto cursor-pointer mx-2`" style="font-size: 1.4rem"></i>
        <span class="my-auto">Solicitudes</span>
      </div>
      <div class="flex items-center gap-4">
        <Button label="Primary" class="p-button-text p-button-rounded my-auto ml-auto mx-2 p-0 icon-hidde"
                @click.stop="displayConf = true"
                v-if="$can('pharmasan.gestion.calidad.mapa-procesos.administrador')"
                >
          <SettingsIcon class="block mx-auto"/>
        </Button>
        <Button
          type="button"
          icon="pi pi-file-excel"
          class="p-button-outlined p-button-success"
          v-tooltip.top="{ value: 'Descargar reporte' }"
          @click="descargarExcel()"
        />
        <Button
          icon="pi pi-plus"
          label="Agregar"
          v-tooltip.top="{ value: 'Agregar Solicitud' }"
          v-if="$can('pharmasan.gestion.calidad.mapa-procesos.crear-solicitudes') || $can('pharmasan.gestion.calidad.mapa-procesos.administrador')"
          @click="crearSolicitud()"
        />
      </div>
    </div>
    <DataTable
      :value="dataSolicitudes.rows"
      dataKey="id"
      responsiveLayout="scroll"
      class="p-datatable-sm"
      v-model:filters="filters"
      filterDisplay="menu"
    >
      <template #empty>
        No hay solicitudes para mostrar
      </template>
      <template #header>
        <div class="flex justify-end">
          <div class="flex justify-between gap-4">
            <div class="flex">
              <Button type="button"
                      icon="pi pi-filter-slash text-sm"
                      label="Limpiar filtros"
                      class="p-button-outlined text-sm font-medium mr-2"
                      @click="clearFilter1()"
              />
            </div>
          </div>
        </div>
      </template>

      <Column field="id" header="Id."/>
      <Column field="tipoId" header="Tipo" :showFilterMatchModes="false">
        <template #body="{ data }">
          {{ tipoSolicitud[data.tipoId] || 'N/A' }}
        </template>
        <template #filterclear="">
        </template>
        <template #filter="{filterModel}">
          <Dropdown
            v-model="filterModel.value"
            :options="tiposSolicitudArray"
            optionLabel="label"
            optionValue="value"
            placeholder="Seleccione un tipo"
          />
        </template>
      </Column>
      <Column field="asunto" header="Titulo"/>
      <Column field="createdBy" header="Creado por" :showFilterMatchModes="false">
        <template #body="{ data }">
          {{ data.fullName }}
        </template>
        <template #filterclear="">
        </template>
        <template #filter="{filterModel}"
                  v-if="$can('pharmasan.gestion.calidad.mapa-procesos.administrador') || $can('pharmasan.gestion.calidad.mapa-procesos.responder-solicitudes')">
          <Dropdown
            v-model="filterModel.value"
            :options="usuarios"
            optionLabel="fullName"
            optionValue="createdBy"
            placeholder="Seleccione una persona"
          />
        </template>
      </Column>
      <Column field="createdAt" header="Fecha Creación" :showFilterMatchModes="false">
        <template #body="{ data }">
          {{ dayjs(data.createdAt).format('DD/MM/YYYY') }}
        </template>
        <template #filterclear="">
        </template>
        <template #filter="{filterModel}">
          <!--          <input type="date" v-model="filterModel.value" class="p-inputtext p-component p-filled">-->
          <!--          <DatePicker v-model="filterModel.model" is-range mode="date"/>-->
          <DatePicker v-model="filterModel.value" is-range mode="date">
            <template v-slot="{ inputValue, inputEvents }">
              <div class="flex justify-center items-center">
                <input
                  :value="inputValue.start"
                  v-on="inputEvents.start"
                  class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
                />
                <svg
                  class="w-4 h-4 mx-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                  />
                </svg>
                <input
                  :value="inputValue.end"
                  v-on="inputEvents.end"
                  class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
                />
              </div>
            </template>
          </DatePicker>
        </template>
      </Column>

      <Column field="estadoId" header="Estado" :filterMenuStyle="{'width':'14rem'}" :showFilterMatchModes="false">
        <template #body="{data}">
          <span v-if="estadoSolicitud[data.estadoId]"
            class="flex justify-center rounded-md font-bold"
            :style="`background-color: ${estadoSolicitud[data.estadoId].bg}; color: ${estadoSolicitud[data.estadoId].color}`"
          >{{ estadoSolicitud[data.estadoId].nombre }}</span>
        </template>
        <template #filterclear="">
        </template>
        <template #filter="{filterModel}">
          <!--          <Dropdown v-model="" :options="" optionLabel="nombre" optionValue="id"-->
          <!--                    placeholder="">-->
          <!--            <template #option="{ option }">-->
          <!--              <span-->
          <!--                class="flex justify-center rounded-md font-bold"-->
          <!--                :style="`background-color: ${estadoSolicitud[option.value || 1].bg}; color: ${estadoSolicitud[option.value || 1].color}`"-->
          <!--              >{{ estadoSolicitud[option.value || 1].text }}</span>-->
          <!--            </template>-->
          <!--          </Dropdown>-->

          <Dropdown v-model="filterModel.value" :options="estadoSolicitudArray" optionLabel="nombre" optionValue="id"
                    placeholder="Seleccione un estado">
            <template #option="slotProps">
              <span v-if="estadoSolicitud[`${slotProps.option.id}`]"
                class="flex justify-center rounded-md font-bold"
                :style="`background-color: ${estadoSolicitud[`${slotProps.option.id}`].bg}; color: ${estadoSolicitud[`${slotProps.option.id}`].color}`"
              >{{ estadoSolicitud[`${slotProps.option.id}`].nombre }}</span>
            </template>
          </Dropdown>
        </template>
      </Column>
      <Column header="Acciones">
        <template #body="{ data }">
          <div class="flex justify-center">
            <Button
              icon="pi pi-eye"
              class="p-button-rounded p-button-text p-button-info"
              v-tooltip.top="{ value: 'Ver Solicitud' }"
              @click="responder(data)"
            />
          </div>
        </template>
      </Column>

    </DataTable>
    <Paginator
      :first="paginate.offset"
      :rows="paginate.limit"
      :totalRecords="paginate.total"
      :rowsPerPageOptions="[10,20,30,paginate.total]"
      @page="onPage($event)"
    />
    <!-- BEGIN: modal config -->
    <Dialog v-model:visible="displayConf" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '40vw'}" :disableMask="true" :modal="true">
      <template v-slot:header>
        <div class="w-full">
          <div class="flex justify-center">
            <FilePlusIcon class="block mr-2 h-5"/>
            <b>Configurar Solicitudes</b>
          </div>
        </div>
      </template>
      <div class="mb-5 flex flex-col">
        <label class="mb-2">Encargado de revisión</label>
        <Dropdown v-model="configuracion.encargado" :options="ListEncargados" @change="confSolicitudes('encargado')"
                  optionLabel="userName" placeholder="Seleccione una solicitud"/>
        <!-- <span v-if="!configuracion.encargado" class="text-xs text-red-600 ">Debe seleccionar un encargado</span> -->
      </div>
      <div class="gap-4 grid grid-cols-2 mb-5">
        <div class="flex flex-col">
          <label class="mb-2">Area</label>
          <Dropdown v-model="configuracion.area" :options="areas" optionLabel="name"
                    placeholder="Seleccione una solicitud"/>
        </div>
        <div class="flex flex-col">
          <label class="mb-2">Encargado del area</label>
          <Dropdown v-model="configuracion.director" :options="ListDirectores" @change="confSolicitudes('director')"
                    optionLabel="userName" placeholder="Seleccione una solicitud"/>
        </div>
      </div>
      <template #footer>
        <!-- <Button label="Guardar" icon="pi pi-check" autofocus @click="confSolicitudes()" /> -->
        <Button label="Aceptar" icon="pi pi-check" class="p-button-secondary p-button-outlined"
                @click="displayConf = false"/>
      </template>
    </Dialog>
    <!-- END: modal config -->
  </div>
  <ModalSolicitudes ref="modalSolicitudes"/>
  <ModalVerResponder ref="modalVerResponder" @openModalPublicar="openModalPublicar" />
  <ModalPublicar ref="modalPublicar"/>
</template>
<script>

import ModalSolicitudes from '@/apps/pharmasan/calidad/views/mapa/modalSolicitudes.vue'
import ModalVerResponder from '@/apps/pharmasan/calidad/views/mapa/modalVerResponder.vue'
import { ref, onMounted, watch, computed, onBeforeMount } from 'vue'
import ModalSolicitudesService from '@/apps/pharmasan/calidad/views/mapa/services/modalSolicitudes.service'
import dayjs from 'dayjs'
import { useRouter, useRoute } from 'vue-router'
import { DatePicker } from 'v-calendar'
import Swal from 'sweetalert2'
import ConfService from './services/confSolicitudes.service'
import store from './store'
import ModalPublicar from '@/apps/pharmasan/calidad/views/mapa/components/modalPublicar.vue'

export default {
  name: 'CalidadSolicitudes',
  components: {
    ModalPublicar,
    ModalSolicitudes,
    ModalVerResponder,
    DatePicker
  },
  setup () {
    // Referencias
    const modalSolicitudes = ref()
    const modalVerResponder = ref()
    const modalPublicar = ref()
    const ListEncargados = ref([])
    const ListAreas = ref([])
    const ListDirectores = ref([])
    const displayConf = ref(false)
    const configuracion = ref({})
    const _ConfService = new ConfService()
    const _ModalSolictudesService = new ModalSolicitudesService()
    const router = useRouter()
    const route = useRoute()
    const mapaProceso = ref(null)
    const fileFolders = ref(null)

    // Computed
    const dataSolicitudes = computed(() => store.getters.getSolicitudes)
    const paginate = computed(() => {
      return {
        limit: 10,
        offset: 0,
        total: dataSolicitudes.value.count
      }
    })

    // Data
    const usuarios = ref([])
    const areas = ref([])
    const tiposSolicitudArray = [
      { label: 'Creación', value: 1 },
      { label: 'Modificación', value: 2 },
      { label: 'Inactivación', value: 3 }
    ]
    const tipoSolicitud = ref({
      1: 'Creación',
      2: 'Modificación',
      3: 'Inactivacion'
    })
    const estadoSolicitud = ref({})
    const estadoSolicitudArray = ref([])

    const filters = ref({
      estadoId: { constraints: [{ value: null }] },
      createdBy: { constraints: [{ value: null }] },
      areaId: { constraints: [{ value: null }] },
      tipoId: { constraints: [{ value: null }] },
      createdAt: { constraints: [{ value: null }] }
    })

    // Metodos
    // eslint-disable-next-line camelcase
    const responder = ({ id, respuesta_id, mapa }) => {
      mapaProceso.value = mapa
      store.dispatch('updateRespuestaId', respuesta_id)
      modalVerResponder.value.openModal(id)
    }
    const getSolicitudes = async (filtros = null) => {
      if (filtros === null) {
        const { query } = route
        filtros = {}
        for (const [index, item] of Object.entries(query)) {
          filtros[index] = item
        }
      }
      _ModalSolictudesService.getEstados().then(async ({ data }) => {
        estadoSolicitudArray.value = data
        estadoSolicitud.value = data.reduce((obj, item) => {
          obj[parseInt(item.id)] = item
          return obj
        }, {})
        await store.dispatch('getSolicitudes', {
          limit: paginate.value.limit,
          offset: paginate.value.offset,
          ...filtros
        })
      })
    }
    const onPage = (event) => {
      paginate.value.offset = event.first
      paginate.value.limit = event.rows
      getSolicitudes()
    }
    const clearFilter1 = () => {
      filters.value = {
        estadoId: { constraints: [{ value: null }] },
        createdBy: { constraints: [{ value: null }] },
        areaId: { constraints: [{ value: null }] },
        tipoId: { constraints: [{ value: null }] },
        createdAt: { constraints: [{ value: null }] }
      }
    }
    const crearSolicitud = () => {
      router.push({ name: 'pharmasan.gestion.calidad.mapa-procesos.crear-solicitudes' })
    }
    const openModalPublicar = (data) => {
      // modalPublicar.value.setData(data)
      console.log('openModalPublicar', fileFolders.value)
      data.folders = fileFolders.value
      modalPublicar.value.getMapa(mapaProceso.value, data)
    }

    // Watchers
    watch(filters, () => {
      const filtros = {}
      for (const [index, item] of Object.entries(filters.value)) {
        if (index === 'createdAt') {
          if (item.constraints[0].value) {
            filtros[index] = JSON.stringify(item.constraints[0].value)
          }
        } else {
          filtros[index] = item.constraints[0].value
        }
      }
      router.push({ query: { ...filtros } }).then(() => {
        getSolicitudes()
      })
    })

    const descargarExcel = async () => {
      let flag = false
      const filtros = {}
      for (const [index, item] of Object.entries(filters.value)) {
        if (item.constraints[0].value) {
          filtros[index] = item.constraints[0].value
          flag = true
        }
      }
      if (!flag) {
        await Swal.fire({
          title: 'Error!',
          text: 'Debe seleccionar al menos un filtro para generar el reporte',
          icon: 'error'
        })
      } else {
        const url = _ModalSolictudesService.generarReporte(filtros)
        window.open(url, '_blank')
      }
    }

    const getEncargados = () => {
      _ModalSolictudesService.getEncargados().then(({ data }) => {
        // console.log('getEncargados---------', data)
        ListEncargados.value = data
      })
    }
    const getDirectores = ({ id }) => {
      _ModalSolictudesService.getEncargados({ area: id }).then(({ data }) => {
        // console.log('lo que lista las directores-----', data)
        data.map(m => {
          var arrayCargo = m.cargo.split(' ')
          m.cargoNombre = arrayCargo.shift() + ' - ' + m.userName
          return { areaId: m.areaId, userId: m.userId, userName: m.cargoNombre }
        })
        if (data) {
          ListDirectores.value = data
        }
      })
    }
    const listConfSolicitudes = (tipo = 'conf_revisar') => {
      _ConfService.getConfEncargado({
        area: configuracion.value.area ? configuracion.value.area.id : 1,
        nameConfig: tipo
      }).then(({ data }) => {
        if (data[0]) {
          // var encarg = { areaId: data.areaId, userId: data.userId, userName: data.userName }
          if (tipo === 'conf_revisar') {
            configuracion.value.encargado = data[0]
          } else {
            var arrayCargo = data[0].cargo.split(' ')
            data[0].cargoNombre = arrayCargo.shift() + ' - ' + data[0].userName
            configuracion.value.director = data[0]
            // console.log('elo director-----', data[0])
          }
          // configuracion.value.idEncargado = data.id
        }
      })
    }

    const confSolicitudes = (tipo = 'encargado') => {
      var dataSend = {}
      if (tipo === 'encargado') {
        dataSend = configuracion.value.encargado
      } else {
        dataSend = configuracion.value.director
      }
      _ConfService.putConfEncargado(dataSend).then(({ data }) => {
        Swal.fire({
          title: 'Exito',
          text: 'Se actualizó el responsable con exito',
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
            displayConf.value = false
          }
        })
      })
    }
    watch(() => configuracion.value.area, (a) => {
      getDirectores(a)
      listConfSolicitudes('conf_aprobar')
    })
    // watch(() => configuracion.value.director, (d) => {
    //   confSolicitudes('director')
    // })
    // watch(() => configuracion.value.encargado, (e) => {
    //   confSolicitudes('encargado')
    // })

    onBeforeMount(async () => {
      await getSolicitudes()
    })
    onMounted(async () => {
      await listConfSolicitudes()
      await getEncargados()
      _ModalSolictudesService.getCreadoPorList().then(({ data }) => {
        usuarios.value = data
      })
      _ModalSolictudesService.getAreasList().then(({ data }) => {
        areas.value = data
      })
    })

    return {
      descargarExcel,
      modalSolicitudes,
      dataSolicitudes,
      tipoSolicitud,
      tiposSolicitudArray,
      dayjs,
      responder,
      estadoSolicitud,
      modalVerResponder,
      paginate,
      getSolicitudes,
      onPage,
      filters,
      clearFilter1,
      estadoSolicitudArray,
      usuarios,
      areas,
      crearSolicitud,
      ListEncargados,
      ListAreas,
      ListDirectores,
      configuracion,
      getEncargados,
      confSolicitudes,
      displayConf,
      openModalPublicar,
      modalPublicar
    }
  }
}
</script>
<style lang="scss">
// .p-dropdown-panel {
//     position: fixed!important;
//     display: block!important;
// }
</style>
