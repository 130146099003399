import http from '../../../../../../libs/http'
const url = process.env.VUE_APP_MICROSERVICE_API_CALIDAD_PHARMASAN

export default class ModalSolicitudesService {
  getConfEncargado (params) {
    return http.get(`${url}/solicitudes/configurar`, {
      params: {
        ...params
      }
    })
  }

  putConfEncargado (params) {
    return http.put(`${url}/solicitudes/configurar`, params, {
      headers: {
        loading: true
      }
    })
  }
}
